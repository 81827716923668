import React, { useState, useRef, useEffect } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import type { HotTableClass } from '@handsontable/react';

function App() {
  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState({
    unkouKubun: '',
    unkouShubetsu: '',
    hacchiCode: '',
    hacchi: '',
    shuppatsuTenpo: '',
    unkouBangou: '',
    sekisaiRyo: '',
    kosu: '',
    kinyohinKosu: '',
    shuppatsuJikan: '',
    tateyoriTenpo: '',
    nyujoutenCode: '',
    nyujoutenMei: '',
    sekisaiRyoN: '',
    kosuN: '',
    kinyohinKosuN: '',
    chakuJikan: '',
    sekiKaishi: '',
    sekiShuryo: '',
  });

  const openDialog = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
    if (searchButtonRef.current) {
      searchButtonRef.current.focus(); // Focus back to the 検索 button
    }
  };

  // Array to hold references to form elements
  const refs = useRef<(HTMLInputElement | HTMLSelectElement | HTMLButtonElement | null)[]>([]);
  const dialogInputRef = useRef<HTMLInputElement | null>(null);
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const hotTableRef = useRef<HotTableClass>(null);

  useEffect(() => {
    if (refs.current[0]) {
      refs.current[0].focus();
    }
  }, []);
  useEffect(() => {
    if (showDialog && dialogInputRef.current) {
      dialogInputRef.current.focus();
    }
  }, [showDialog]);
  useEffect(() => {
    const inputElement = refs.current[17];
    const hotTableInstance = hotTableRef.current?.hotInstance;
    if (inputElement && hotTableInstance) {
      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (hotTableInstance) {
            const selectedRanges = hotTableInstance.getSelected();

            if (selectedRanges && selectedRanges.length > 0) {
              const [rowStart, colStart, rowEnd, colEnd] = selectedRanges[0];
              if (rowStart === 0 && colStart === 0 && rowEnd === 0 && colEnd === 0) {
                hotTableInstance.deselectCell();
                inputElement.focus();
                return;
              }
            }
          }
        }
      };
      
      // Attach the event listener to the window to capture key events globally
      window.addEventListener('keyup', handleKeyUp as EventListener);

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('keyup', handleKeyUp as EventListener);
      };
    }
  }, [hotTableRef, refs]);
  useEffect(() => {
    const inputElement = refs.current[3];
    const hotTableInstance = hotTableRef.current?.hotInstance;
    if (inputElement && hotTableInstance) {
      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (hotTableInstance) {
            const selectedRanges = hotTableInstance.getSelected();

            if (selectedRanges && selectedRanges.length > 0) {
              const [rowStart, colStart, rowEnd, colEnd] = selectedRanges[0];
              if (rowStart === 2 && colStart === 0 && rowEnd === 2 && colEnd === 0) {
                hotTableInstance.deselectCell();
                inputElement.focus();
                return;
              }
            }
          }
        }
      };
      
      // Attach the event listener to the window to capture key events globally
      window.addEventListener('keyup', handleKeyUp as EventListener);

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('keyup', handleKeyUp as EventListener);
      };
    }
  }, [hotTableRef, refs]);

  // Reset all input fields on Ctrl + R
  const handleReset = () => {
    setFormData({
      unkouKubun: '',
      unkouShubetsu: '',
      hacchiCode: '',
      hacchi: '',
      shuppatsuTenpo: '',
      unkouBangou: '',
      sekisaiRyo: '',
      kosu: '',
      kinyohinKosu: '',
      shuppatsuJikan: '',
      tateyoriTenpo: '',
      nyujoutenCode: '',
      nyujoutenMei: '',
      sekisaiRyoN: '',
      kosuN: '',
      kinyohinKosuN: '',
      chakuJikan: '',
      sekiKaishi: '',
      sekiShuryo: '',
    });

    refs.current.forEach(ref => {
      if (ref) ref.value = '';
    });
  };

  // Show a popup with the current form data on Ctrl + T
  const handleShowPopup = () => {
    const formDataList = Object.entries(formData).map(([key, value]) => (
      <div key={key}>
        <strong>{key}:</strong> {value}
      </div>
    ));

    alert(
      <div>
        {formDataList}
        <div style={{ marginTop: '10px' }}>Enterで登録します</div>
      </div>
    );
  };

  // Handle F12 and F8 key presses
  const handleFunctionKeys = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'F12') {
      e.preventDefault();
      window.close(); // Close the window
    } else if (e.key === 'F2') {
      e.preventDefault();
      alert('登録しました'); // Show the 登録しました dialog
    } else if (e.key === 'F8') {
      e.preventDefault();
      openDialog();
    }
  };

  // Key down handler for custom shortcuts and navigation
  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    handleFunctionKeys(e);

    if (e.ctrlKey) {
      switch (e.key) {
        case 'r':
        case 'R':
          e.preventDefault();
          handleReset();
          break;
        case 'p':
        case 'P':
          e.preventDefault();
          handleShowPopup();
          break;
        default:
          break;
      }
    }

    const currentIndex = refs.current.indexOf(document.activeElement as HTMLInputElement | HTMLSelectElement | HTMLButtonElement);
    const hotInstance = hotTableRef.current?.hotInstance;

    if (currentIndex !== -1) {
      switch (e.key) {
        // Handle right arrow key
        case 'ArrowRight':
          e.preventDefault();
          if (currentIndex < 3) refs.current[currentIndex + 1]?.focus(); // Navigate within the first row
          else if (currentIndex === 3) refs.current[4]?.focus(); // 発地 -> 運行番号
          else if (currentIndex === 4) refs.current[9]?.focus(); // 運行番号 -> 荷積店コード
          else if (currentIndex === 9) refs.current[10]?.focus(); // 荷積店コード -> 検索 button
          else if (currentIndex === 10) refs.current[11]?.focus(); // 検索 button -> 荷積店名
          else if (currentIndex === 5) refs.current[11]?.focus(); // 積載量（％） -> 荷積店名
          else if (currentIndex === 6) refs.current[12]?.focus(); // 個数 -> 積載量（％）
          else if (currentIndex === 7) refs.current[13]?.focus(); // 貴量品個数 -> 個数
          else if (currentIndex === 8) refs.current[14]?.focus(); // 出発時間 -> 貴量品個数
          break;

        // Handle left arrow key
        case 'ArrowLeft':
          e.preventDefault();
          if (currentIndex > 0 && currentIndex <= 3) refs.current[currentIndex - 1]?.focus(); // Navigate within the first row
          else if (currentIndex === 4) refs.current[3]?.focus(); // 運行番号 -> 発地
          else if (currentIndex === 10) refs.current[9]?.focus(); // 検索 button -> 荷積店コード
          else if (currentIndex === 9) refs.current[4]?.focus(); // 荷積店コード-> 運行番号
          else if (currentIndex === 11) refs.current[5]?.focus();
          else if (currentIndex === 12) refs.current[6]?.focus();
          else if (currentIndex === 13) refs.current[7]?.focus();
          else if (currentIndex === 14) refs.current[8]?.focus();

          break;

        // Handle down arrow key
        case 'ArrowDown':
          e.preventDefault();
          // Moving down within 出発店舗 div
          if (currentIndex === 4) refs.current[5]?.focus(); // 運行番号 -> 積載量（％）
          else if (currentIndex === 3) refs.current[4]?.focus(); // 発地 -> 運行番号
          else if (currentIndex === 5) refs.current[6]?.focus(); // 積載量（％） -> 個数
          else if (currentIndex === 6) refs.current[7]?.focus(); // 個数 -> 貴量品個数
          else if (currentIndex === 7) refs.current[8]?.focus(); // 貴量品個数 -> 出発時間

          // Moving down within 立寄店舗 div
          else if (currentIndex === 10) refs.current[11]?.focus(); // 検索ボタン -> 荷積店名
          else if (currentIndex === 11) refs.current[12]?.focus(); // 荷積店名 -> 積載量（％）
          else if (currentIndex === 12) refs.current[13]?.focus(); // 積載量（％） -> 個数
          else if (currentIndex === 13) refs.current[14]?.focus(); // 個数 -> 貴量品個数
          else if (currentIndex === 14) refs.current[15]?.focus(); // 貴量品個数 -> 着時間
          else if (currentIndex === 15) refs.current[16]?.focus(); // 着時間 -> 積開始
          else if (currentIndex === 16) refs.current[17]?.focus(); // 積開始 -> 積終了
          else if (currentIndex === 17) {
            if (hotInstance) {
              hotInstance.selectCell(0, 0, 0, 0);
            }
          }
          break;

        // Handle up arrow key
        case 'ArrowUp':
          e.preventDefault();
          // Moving up within 出発店舗 div
          if (currentIndex === 5) refs.current[4]?.focus(); // 積載量（％） -> 運行番号
          else if (currentIndex === 4) refs.current[3]?.focus(); // 運行番号 -> 発地
          else if (currentIndex === 6) refs.current[5]?.focus(); // 個数 -> 積載量（％）
          else if (currentIndex === 7) refs.current[6]?.focus(); // 貴量品個数 -> 個数
          else if (currentIndex === 8) refs.current[7]?.focus(); // 出発時間 -> 貴量品個数

          // Moving up within 立寄店舗 div
          else if (currentIndex === 11) refs.current[10]?.focus(); // 荷積店名 -> 荷積店コード
          else if (currentIndex === 12) refs.current[11]?.focus(); // 積載量（％） -> 荷積店名
          else if (currentIndex === 13) refs.current[12]?.focus(); // 個数 -> 積載量（％）
          else if (currentIndex === 14) refs.current[13]?.focus(); // 貴量品個数 -> 個数
          else if (currentIndex === 15) refs.current[14]?.focus(); // 着時間 -> 貴量品個数
          else if (currentIndex === 16) refs.current[15]?.focus(); // 積開始 -> 着時間
          else if (currentIndex === 17) refs.current[16]?.focus(); // 積終了 -> 積開始
          break;

        // Handle Enter key
        case 'Enter':
          if (currentIndex === 10) {
            e.preventDefault();
            openDialog(); // Trigger the popup when Enter is pressed on the 検索 button
          }
          else if (currentIndex === 3) refs.current[4]?.focus(); // 発地 -> 運行番号
          else if (currentIndex === 4) refs.current[5]?.focus(); // 運行番号 -> 積載量（％）
          else if (currentIndex === 5) refs.current[6]?.focus(); // 積載量（％） -> 個数
          else if (currentIndex === 6) refs.current[7]?.focus(); // 個数 -> 貴量品個数
          else if (currentIndex === 7) refs.current[8]?.focus(); // 貴量品個数 -> 出発時間
          else if (currentIndex === 8) refs.current[9]?.focus(); // 貴量品個数 -> 出発時間

          // Moving down within 立寄店舗 div
          else if (currentIndex === 9) refs.current[11]?.focus(); 
          else if (currentIndex === 11) refs.current[12]?.focus(); 
          else if (currentIndex === 12) refs.current[13]?.focus(); // 積載量（％） -> 個数
          else if (currentIndex === 13) refs.current[14]?.focus(); // 個数 -> 貴量品個数
          else if (currentIndex === 14) refs.current[15]?.focus(); // 貴量品個数 -> 着時間
          else if (currentIndex === 15) refs.current[16]?.focus(); // 着時間 -> 積開始
          else if (currentIndex === 16) refs.current[17]?.focus(); // 積開始 -> 積終了
          else if (currentIndex === 17) {
            if (hotInstance) {
              hotInstance.selectCell(0, 0, 0, 0);
            }
          }
          break;
        case 'Escape':
          e.preventDefault();
          closeDialog(); // Close the dialog
          refs.current[10]?.focus(); // Set focus to the element at index 10 after closing the dialog
          break;
        default:
          break;
      }
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', display: 'flex', flexDirection: 'column', alignItems: 'center' }} onKeyDown={keyDownHandler} tabIndex={0}>
      <h3 style={{ textAlign: 'center', borderBottom: '2px solid gray', paddingBottom: '10px' }}>
        JAVA システム<br />キーボード入力制御　サンプル
      </h3>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', width: '100%' }}>
        <div style={{ marginRight: '20px', border: '1px solid #ddd', padding: '10px' }}>
          <label style={{ padding: '5px' }}>運行区分</label>
          <select ref={(el) => (refs.current[0] = el)} style={{ marginLeft: '10px', border: '1px solid #ccc' }}>
            <option value="">選択してください</option>
            <option value="1">運行</option>
            <option value="2">運行2</option>
            <option value="3">運行3</option>
            <option value="4">運行4</option>
            <option value="5">運行5</option>
          </select>
        </div>
        <div style={{ marginRight: '20px', border: '1px solid #ddd', padding: '5px' }}>
          <label style={{ padding: '5px' }}>運行種別</label>
          <select ref={(el) => (refs.current[1] = el)} style={{ marginLeft: '10px', border: '1px solid #ccc' }}>
            <option value="">選択してください</option>
            <option value="1">急便・幹線（上り）1</option>
            <option value="2">急便・幹線（上り）2</option>
            <option value="3">急便・幹線（上り）3</option>
            <option value="4">急便・幹線（上り）4</option>
            <option value="5">急便・幹線（上り）5</option>
            <option value="6">急便・幹線（上り）6</option>
          </select>
        </div>
        <div style={{ marginRight: '20px', border: '1px solid #ddd', padding: '10px' }}>
          <label style={{ padding: '5px' }}>発地コード</label>
          <select ref={(el) => (refs.current[2] = el)} style={{ marginLeft: '10px', border: '1px solid #ccc' }}>
            <option value="">選択してください</option>
            <option value="1">3-6i1</option>
            <option value="2">3-6i2</option>
            <option value="3">3-6i3</option>
            <option value="4">3-6i4</option>
            <option value="5">3-6i5</option>
            <option value="6">3-6i6</option>
          </select>
        </div>
        <div style={{ border: '1px solid #ddd', padding: '10px' }}>
          <label style={{ padding: '5px' }}>発地</label>
          <input type="text" ref={(el) => (refs.current[3] = el)} style={{ marginLeft: '10px', border: '1px solid #ccc', backgroundColor: '#fff' }} />
        </div>
      </div>

      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ border: '1px solid gray', padding: '10px', width: '300px', }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>出発店舗</label>
            <label>1</label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>運行番号</label>
            <input type="text" ref={(el) => (refs.current[4] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>積載量（％）</label>
            <input type="text" ref={(el) => (refs.current[5] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>個数</label>
            <input type="text" ref={(el) => (refs.current[6] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>貴量品個数</label>
            <input type="text" ref={(el) => (refs.current[7] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>出発時間</label>
            <input type="text" ref={(el) => (refs.current[8] = el)} style={{ flex: 1 }} />
          </div>
        </div>

        <div style={{ border: '1px solid gray', padding: '10px', width: '400px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>立寄店舗</label>
            <label>1</label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>荷積店コード</label>
            <div style={{ display: 'flex', flex: 1 }}>
              <input type="text" ref={(el) => (refs.current[9] = el)} style={{ flex: 1 }} />
              <button onClick={openDialog} ref={(el) => (refs.current[10] = el)} style={{ marginLeft: '10px' }}>検索</button>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>荷積店名</label>
            <input type="text" ref={(el) => (refs.current[11] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>積載量（％）</label>
            <input type="text" ref={(el) => (refs.current[12] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>個数</label>
            <input type="text" ref={(el) => (refs.current[13] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>貴量品個数</label>
            <input type="text" ref={(el) => (refs.current[14] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>着時間:</label>
            <input type="text" ref={(el) => (refs.current[15] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>積開始</label>
            <input type="text" ref={(el) => (refs.current[16] = el)} style={{ flex: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>積終了</label>
            <input type="text" ref={(el) => (refs.current[17] = el)} style={{ flex: 1 }} />
          </div>
        </div>
      </div>

      {/* Handsontable below the divs 出発店舗1 and 立寄店舗1 */}
      <div style={{ marginTop: '10px', width: 'calc(100% - 50px)', maxWidth: '670px' }}>
        <label style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>明細情報</label>
        <div style={{ width: 'calc(100% - 50px)', maxWidth: '670px' }}>
          <HotTable
            ref={hotTableRef}
            data={[
              ['サンプル', '', '', ''],
              ['', '', '', ''],
              ['', '', '', ''],
            ]}
            colHeaders={['明細A', '明細B', '明細C', '明細D']}
            columns={[
              {},
              {},
              {},
              {},
            ]}
            rowHeaders={true}
            width="100%"
            height="auto"
            stretchH="all"
            licenseKey="non-commercial-and-evaluation"
          />
        </div>
      </div>
      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ border: '1px solid gray', backgroundColor: '#f5f5f5' }}>
          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: '10px' }}>F12:</td>
                <td>Exit</td>
              </tr>
              <tr>
                <td>F2:</td>
                <td>登録する</td>
              </tr>
              <tr>
                <td>F8:</td>
                <td>詳細検索</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ border: '1px solid gray', backgroundColor: '#f5f5f5' }}>
          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: '10px' }}>←↑↓→　BS Enter</td>
              </tr>
              <tr>
                <td>カーソルが次の入力項目に移動します</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {showDialog && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', border: '1px solid gray', padding: '20px', zIndex: 1000 }}>
          <h3>検索ダイアログ</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <table border={1}>
              <thead>
                <tr>
                  <th>項目A</th>
                  <th>項目B</th>
                  <th>項目C</th>
                  <th>項目D</th>
                  <th>項目EE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                </tr>
                <tr>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                </tr>
                <tr>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                  <td>サンプル</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>（ESC:閉じ）</p>
        </div>
      )}

    </div>

  );
}

export default App;
